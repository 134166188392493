export const developmentStageOptions = [
  { value: "product development", label: "Product Development" },
  { value: "start-up", label: "Start-up" },
  { value: "growth", label: "Growth" },
  { value: "expansion", label: "Expansion" },
  { value: "maturity", label: "Maturity" },
  { value: "non-profit organization", label: "Non-profit Organization" },
];

export const budgetOptions = [
  { value: "less than 25.000 eur", label: "Less than 25.000 Eur" },
  { value: "25.000 - 50.000 eur", label: "25.000 - 50.000 Eur" },
  { value: "50.000 – 150.000 eur", label: "50.000 – 150.000 Eur" },
  { value: "more than 150.000 eur", label: "More than 150.000 Eur" },
];

export const whatToDoOptions = [
  {
    value: "build something new",
    label: "Build something new",
    name: "whatToDo",
  },
  {
    value: "improve existing project",
    label: "Improve existing project",
    name: "whatToDo",
  },
  { value: "extend my team", label: "Extend my team", name: "whatToDo" },
];

export const projectTypeOptions = [
  { value: "web app", label: "Web app", name: "projectType" },
  { value: "mobile app", label: "Mobile app", name: "projectType" },
  { value: "ecommerce site", label: "eCommerce site", name: "projectType" },
  {
    value: "enterprise system",
    label: "Enterprise system",
    name: "projectType",
  },
  { value: "other", label: "Other", name: "projectType" },
];
