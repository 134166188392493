import React, { FC } from "react";
import { TileCheckbox } from "@/components/basic/TileCheckbox";
import { TileCheckboxOption } from "../declarations";

export interface TileCheckboxGroupProps {
  heading: string;
  options: TileCheckboxOption[];
  errorMessage?: string;
  register: (ref: string, { required: boolean, pattern: RegExp }) => void;
}

export const TileCheckboxGroup: FC<TileCheckboxGroupProps> = ({
  heading,
  options,
  errorMessage,
  register,
}) => (
  <>
    <p className="l-full required">{heading}</p>
    {options.map((option) => (
      <div className="l-one-third-tablet l-half-push-top" key={option.value}>
        <TileCheckbox
          label={option.label}
          name={option.name}
          value={option.value}
          register={register}
        />
      </div>
    ))}
    {!!errorMessage && (
      <p className="error l-full-mobile d-flex-box">{errorMessage}</p>
    )}
  </>
);
