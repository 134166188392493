import React, { FC } from "react";
import { ValidatedInput } from "@/components/basic/ValidatedInput";

export interface TileCheckboxProps {
  label: string;
  name: string;
  value: string;
  register: (ref: string, { required: boolean, pattern: RegExp }) => void;
}

export const TileCheckbox: FC<TileCheckboxProps> = ({
  label,
  name,
  value,
  register,
}) => {
  return (
    <label className="tileCheckbox">
      <ValidatedInput
        type="checkbox"
        name={name}
        value={value}
        register={register}
      />
      <span>{label}</span>
    </label>
  );
};
